@import "~@ng-select/ng-select/themes/default.theme.css";

/* archwizard */
@import "../node_modules/angular-archwizard/archwizard.css";

/* ngx-datatable */
@import url("../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css");
@import url("../node_modules/@swimlane/ngx-datatable/assets/icons.css");
@import url("../src/assets/css/ngx-datatable-custom-styles.css");

.col-lg-12.col-md-12.col-sm-12 h5 {
  transition: 1s;
}
@media (max-width: 700px) {
  .col-lg-12.col-md-12.col-sm-12 h5 {
    font-size: 16px !important;
    text-align: center;
  }
}
.login-page .content .container {
  margin-top: 0px;
}
.required::after {
  content: " *";
  color: red;
}
/* .home-section::-webkit-scrollbar, */
.sidebar-wrapper::-webkit-scrollbar,
.row-clientes-body::-webkit-scrollbar,
.accion-row::-webkit-scrollbar,
.boxcontainer::-webkit-scrollbar,
.bodyColumn::-webkit-scrollbar,
.fixTableHead::-webkit-scrollbar,
.accordion_fields::-webkit-scrollbar,
.commentBox::-webkit-scrollbar,
.dropdownNotifications::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
.wrapper-table::-webkit-scrollbar,
html::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  border-radius: 7px !important;
}

/* .home-section::-webkit-scrollbar-track, */
.sidebar-wrapper::-webkit-scrollbar-track,
.row-clientes-body::-webkit-scrollbar-track,
.accion-row::-webkit-scrollbar-track,
.boxcontainer::-webkit-scrollbar-track,
.bodyColumn::-webkit-scrollbar-track,
.fixTableHead::-webkit-scrollbar-track,
.accordion_fields::-webkit-scrollbar-track,
.commentBox::-webkit-scrollbar-track,
.dropdownNotifications::-webkit-scrollbar-track,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
.wrapper-table::-webkit-scrollbar-track,
html::-webkit-scrollbar-track {
  background: #aaa !important;
  border: 1px solid #aaa !important;
  border-radius: 7px !important;
}
/* .home-section::-webkit-scrollbar-thumb, */
.sidebar-wrapper::-webkit-scrollbar-thumb,
.row-clientes-body::-webkit-scrollbar-thumb,
.accion-row::-webkit-scrollbar-thumb,
.boxcontainer::-webkit-scrollbar-thumb,
.bodyColumn::-webkit-scrollbar-thumb,
.fixTableHead::-webkit-scrollbar-thumb,
.accordion_fields::-webkit-scrollbar-thumb,
.commentBox::-webkit-scrollbar-thumb,
.dropdownNotifications::-webkit-scrollbar-thumb,
.ag-body-horizontal-scroll-viewportl::-webkit-scrollbar-thumb,
.wrapper-table::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  background: #3e50b4 !important;
  border: solid 1px #9368e9 !important;
  border-radius: 7px !important;
}

.navbarColor {
  background-color: #262626;
}

.logo {
  /* 
    Eliminado para logo Cobranza
    height: 61px;
     */
  background-color: #f4f3ef;
  border-right: 1px solid #ddd;
}

.logo .logo-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.sidebar-wrapper nav li {
  margin-bottom: 30px;
}

.bootstrap-select {
  width: 100% !important;
}

.ng-select .ng-select-container {
  border: 1px solid #9d9d9d !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 40px;
}
button:focus {
  outline: none;
  outline: none;
}

.switch.switch-medium {
  min-width: 70px !important;
}
.card-modal {
  margin-bottom: 0;
}
.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.col-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.nav-tabs .nav-item .nav-link {
  color: #6c757d;
}

.nav-tabs .nav-item .active {
  color: #3e50b4;
}
.bs-datepicker-head {
  background-color: #3e50b4 !important;
}
.badge {
  font-size: 8px !important;
  padding: 0px;
  color: #fff !important;
}
.fa-spinner {
  color: white;
  font-size: 17px;
}

.iconNotifications i {
  font-size: 24px !important;
}
.iconNotifications {
  position: relative;
}
.iconNotifications .badge {
  position: absolute;
  left: -5px;
  top: 0px;
  font-size: 12px !important;
  padding: 3px 3px;
  border-radius: 50%;
  line-height: 10px;
  width: 20px;
  height: 20px;
}
.comiteNotification {
  font-weight: bold;
  color: #f34235;
}

.dropdownNotifications {
  max-width: 450px;
  min-width: 260px;
  overflow: auto;
  max-height: 350px;
}

.notification-item {
  padding: 10px 20px 10px 15px !important;
  text-align: justify;
  white-space: normal !important;
  border-bottom: 1px solid #6c757d;
  cursor: pointer;
}
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e3e3e3;
}

.item-cursor {
  cursor: pointer;
}

.iconLink .fa-icon {
  font-size: 22px;
  color: white;
  margin-right: 5px;
}

.linkElement {
  height: 30px;
  display: flex;
  flex-direction: row;
}

.caret {
  margin: 9px 0px;
}
.flex-col-center-reverse {
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
  justify-content: center;
  margin-left: 10px;
}
.data .card-category {
  font-size: 1.2rem;
}

.data .card-title {
  font-size: 1.2rem;
}
.gadget-header {
  display: flex;
  flex-direction: row;
}
.gadget-header .title {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.blue-spinner {
  color: rgba(0, 0, 255, 0.567) !important;
  font-size: 110px !important;
}
.fixTableHead {
  overflow-y: auto;
  height: 300px;
}
.fixTableHead thead th {
  position: sticky;
  top: -12px;
}
.fixTableHead table {
  border-collapse: collapse;
  width: 100%;
}
.fixTableHead th,
.fixTableHead td {
  padding: 8px 15px;
  /* border: 2px solid #529432; */
}
.fixTableHead th {
  background: white;
}
.gadget-table {
  padding: 7px 7px;
}
.diferencia {
  color: red;
  font-size: 3rem;
}

/* .styledDropdown{
      background-color: red;
  } */

.styledDropdown .dropdown-item {
  padding: 2px !important;
  font-size: 0.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 180px;
  padding-left: 5px;
}

nav.navbar {
  display: none;
}
@media screen and (max-width: 940px) {
  nav.navbar {
    display: contents;
  }
}
html,
body {
  height: 100%;
}
.matTooltipClass {
  font-weight: bold !important;
  font-size: 12px;
}

/* Google Font Link */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" , sans-serif;
} */
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #fff;
  /* padding: 0px 14px 62px 6px; */
  z-index: 99;
  transition: all 0.5s ease;
  border: 1px solid #333;
  box-shadow: 5px 0 5px -2px rgba(136, 136, 136, 0.432);
}
.hidden {
  transition: all 0.5s ease;
  opacity: 0;
}
.hidden-cobranza {
  transition: all 0.5s ease;
  display: none;
}
.open .hidden {
  opacity: 1;
}
.open .hidden-cobranza {
  display: block;
}

.sidebar.open {
  width: 270px;
}
.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}
.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn {
  text-align: right;
}
.sidebar i {
  color: #45348e;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list {
  margin-top: 20px;
  height: 100%;
}
.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open li .tooltip {
  display: none;
}
.sidebar input {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}
.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1d1b31;
  color: #fff;
}
.sidebar.open .bx-search:hover {
  background: #1d1b31;
  color: #fff;
}
.sidebar .bx-search:hover {
  background: #fff;
  color: #11101d;
}
.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  /* background: #fff; */
}
.sidebar li a.linki:hover {
  background: #3e50b4;
}
.sidebar li a .links_name {
  color: #45348e;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #fff;
}
.sidebar li i,
.sidebar li svg {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.iconLink {
  margin-left: -4px;
  margin-right: 19px;
}
.iconLink svg {
  width: 20px;
  height: 20px;
}
.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #392b75;
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar.open li.profile {
  width: 250px;
}
.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}
.sidebar li.profile .job {
  font-size: 12px;
}
.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #392b75;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar .profile i {
  color: #fff;
}
.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}
@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
#nav-list {
  padding-left: 0px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 82px;
  margin-top: 0px;
}

.home-section {
  /* overflow: auto; */
  position: relative;
  background: #f5f5f5;
  min-height: 100vh;
  top: 0;
  left: 78px;
  right: 0px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 57px;
  display: flex;
  box-shadow: 0px 0 7px 0px rgb(136 136 136) inset;
}
.home-section-wraped {
  left: 270px;
  width: calc(100% - 270px);
}

.wraped-left {
  left: 270px;
  /* width: calc(100% - 270px); */
}
.width-left {
  width: calc(100% - 270px);
}
.wraped-right {
  right: 350px;
  /* width: calc(100% - 348px); */
}
.width-right {
  width: calc(100% - 348px);
}
.width-both-sides {
  width: calc(100% - 540px);
}

.home-section-wraped-bsides {
  left: 270px;
  right: 270px;
  width: calc(100% - 540px);
}

.main-content {
  width: 97.5%;
  margin: 15px;
}

.sidebar-wraper {
  width: 100% !important;
}

.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
  margin: 0 0px 0;
  font-weight: bold;
  display: block;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  padding: 10px 8px;
  line-height: 30px;
  opacity: 0.7;
}

/* .sidebar .sidebar-wrapper, .off-canvas-sidebar .sidebar-wrapper { */
/* overflow: hidden; */
/* } */

ul {
  list-style-type: none;
}

/* .wrapper {
  overflow: hidden;
} */
.li-menu {
  padding-left: 0px;
  transition: all 0.5s ease;
  border: 1px solid #333;
}
.open .li-menu {
  opacity: 1;
  padding-left: 25px;
}
/* .sidebar-normal:after{
  content: '';
  box-shadow: -20px 0px 10px 0px rgb(38 38 38 / 100%) inset; 
} */

.item {
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.item .item-label,
.item .item-value {
  width: 100%;
}
.item .item-label {
  font-size: 12px;
  color: #333;
  text-align: left;
}
.item .item-value {
  font-size: 14px;
  color: #11101d;
  font-weight: bold;
  text-align: right;
}

.item.nombre {
  font-weight: bold;
  margin-bottom: 8px;
}
.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  position: relative;
  height: calc(100vh - 57px);
  /* overflow: auto; */
  overflow-x: hidden;
  width: 260px;
  z-index: 4;
  /* padding-bottom: 25px; */
}
